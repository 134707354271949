import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Project
  project: { status: 0, payload: null },
  setProject: action((state, payload = null) => {
    state.project = payload
  }),
  getProject: thunk(async (actions, id) => {
    actions.setProject({ status: 1 })
    axios.get(`${API_URL}/jobs/${id}`)
      .then(response => actions.setProject({ status: 2, payload: response.data }))
      .catch(e => actions.setProject({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Projects
  projects: { status: 0, payload: null },
  setProjects: action((state, payload = null) => {
    state.projects = payload
  }),
  getProjects: thunk(async (actions, params) => {
    actions.setProjects({ status: 1 })
    axios.get(`${API_URL}/jobs`, { params })
      .then(response => actions.setProjects({ status: 2, payload: response.data }))
      .catch(e => actions.setProjects({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Public Projects
  publicProjects: { status: 0, payload: null },
  setPublicProjects: action((state, payload = null) => {
    state.publicProjects = payload
  }),
  getPublicProjects: thunk(async (actions, params) => {
    actions.setPublicProjects({ status: 1 })
    axios.get(`${API_URL}/jobs/public`, { params })
      .then(response => actions.setPublicProjects({ status: 2, payload: response.data }))
      .catch(e => actions.setPublicProjects({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Project Active Form
  projectActiveForm: 'projectListingDetails',
  setProjectActiveForm: action((state, payload = null) => {
    state.projectActiveForm = payload
  }),
  // POST PROJECT
  postedProjects: { status: 0, payload: null },
  setPostProjects: action((state, payload = null) => {
    state.postedProjects = payload
  }),
  postProjects: thunk(async (actions, data) => {
    actions.setPostProjects({ status: 1 })
    axios.post(`${API_URL}/jobs`, data)
      .then(response => actions.setPostProjects({ status: 2, payload: response.data }))
      .catch(e => actions.setPostProjects({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // UPDATE PROJECT
  updatedProject: { status: 0, payload: null },
  setUpdatedProject: action((state, payload = null) => {
    state.updatedProject = payload
  }),
  updateProject: thunk(async (actions, payload) => {
    const { id, data } = payload
    actions.setUpdatedProject({ status: 1 })
    axios.put(`${API_URL}/jobs/${id}`, data)
      .then(response => actions.setUpdatedProject({ status: 2, payload: response.data }))
      .catch(e => actions.setUpdatedProject({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // DELETE PROJECT
  deletedProject: { status: 0, payload: null },
  setDeletedProject: action((state, payload = null) => {
    state.deletedProject = payload
  }),
  deleteProject: thunk(async (actions, id) => {
    actions.setDeletedProject({ status: 1 })
    axios.delete(`${API_URL}/jobs/${id}`)
      .then(response => actions.setDeletedProject({ status: 2, payload: response.data }))
      .catch(e => actions.setDeletedProject({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Projects Board Results
  resetProjectsBoardResults: false,
  setResetProjectsBoardResults: action((state, payload = null) => {
    state.resetProjectsBoardResults = payload
  }),
  // Project Modal
  projectModal: null,
  setProjectModal: action((state, payload = null) => {
    state.projectModal = payload
  }),
  // Hire Artist / Accept Bid
  hiredArtist: { status: 0, payload: null },
  setHiredArtist: action((state, payload = null) => {
    state.hiredArtist = payload
  }),
  hireArtist: thunk(async (actions, payload = {}) => {
    const { id, data } = payload
    actions.setHiredArtist({ status: 1 })
    axios.put(`${API_URL}/jobs/${id}/hire_artist`, data)
      .then(response => actions.setHiredArtist({ status: 2, payload: response.data }))
      .catch(e => actions.setHiredArtist({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Get Project Milestones
  projectMilestones: { status: 0, payload: null },
  setProjectMilestones: action((state, payload = null) => {
    state.projectMilestones = payload
  }),
  getProjectMilestones: thunk(async (actions, id) => {
    actions.setProjectMilestones({ status: 1 })
    axios.get(`${API_URL}/jobs/${id}/milestones`)
      .then(response => actions.setProjectMilestones({ status: 2, payload: response.data }))
      .catch(e => actions.setProjectMilestones({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Accept Job
  acceptedJob: { status: 0, payload: null },
  setAcceptedJob: action((state, payload = null) => {
    state.acceptedJob = payload
  }),
  acceptJob: thunk(async (actions, payload = {}) => {
    const { id, data } = payload
    actions.setAcceptedJob({ status: 1 })
    axios.put(`${API_URL}/jobs/${id}/accept_job`, data)
      .then(response => actions.setAcceptedJob({ status: 2, payload: response.data }))
      .catch(e => actions.setAcceptedJob({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Submit Milestone
  submittedMilestone: { status: 0, payload: null },
  setSubmittedMilestone: action((state, payload = null) => {
    state.submittedMilestone = payload
  }),
  submitMilestone: thunk(async (actions, payload) => {
    const { id, data } = payload
    actions.setSubmittedMilestone({ status: 1 })
    axios.put(`${API_URL}/jobs/${id}/submit_milestone`, data)
      .then(response => actions.setSubmittedMilestone({ status: 2, payload: response.data }))
      .catch(e => actions.setSubmittedMilestone({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Update Milestone
  updatedMilestone: { status: 0, payload: null },
  setUpdatedMilestone: action((state, payload = null) => {
    state.updatedMilestone = payload
  }),
  updateMilestone: thunk(async (actions, payload) => {
    const { id, data } = payload
    actions.setUpdatedMilestone({ status: 1 })
    axios.put(`${API_URL}/jobs/${id}/update_milestone`, data)
      .then(response => actions.setUpdatedMilestone({ status: 2, payload: response.data }))
      .catch(e => actions.setUpdatedMilestone({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Accept Milestone
  acceptedMilestone: { status: 0, payload: null },
  setAcceptedMilestone: action((state, payload = null) => {
    state.acceptedMilestone = payload
  }),
  acceptMilestone: thunk(async (actions, payload = {}) => {
    const { id, data, iseWallet } = payload
    actions.setAcceptedMilestone({ status: 1 })
    if (!iseWallet){
      axios.put(`${API_URL}/jobs/${id}/release_payment`, data)
        .then(response => actions.setAcceptedMilestone({ status: 2, payload: response.data }))
        .catch(e => actions.setAcceptedMilestone({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
    }
    else{
      actions.setAcceptedMilestone({ status: 2, payload: {} })
    }
  }),
  acceptMilestoneViaEWallet: thunk(async (actions, payload = {}) => {
    const { id, data } = payload
    actions.setAcceptedMilestone({ status: 1 })
  }),
  // Project Milestone Modal for Artist
  milestoneArtistModal: null,
  setMilestoneArtistModal: action((state, payload = null) => {
    state.milestoneArtistModal = payload
  }),
  // Project Milestone Modal for Client
  milestoneClientModal: null,
  setMilestoneClientModal: action((state, payload = null) => {
    state.milestoneClientModal = payload
  }),
  // Project Modal
  offerProjectModal: null,
  setOfferProjectModal: action((state, payload = null) => {
    state.offerProjectModal = payload
  }),
  // Compute Invoice
  computedInvoice: { status: 0, payload: null },
  setComputedInvoice: action((state, payload = null) => {
    state.computedInvoice = payload
  }),
  computeInvoice: thunk(async (actions, payload = {}) => {
    const { id, params } = payload
    actions.setComputedInvoice({ status: 1 })
    axios.get(`${API_URL}/jobs/${id}/compute_invoice`, { params })
      .then(response => actions.setComputedInvoice({ status: 2, payload: response.data }))
      .catch(e => actions.setComputedInvoice({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  editProjectDetails: null,
  setEditProjectDetails: action((state, payload = null) => {
    state.editProjectDetails = payload
  }),
  requestedQoutation: { status: 0, payload: null },
  setRequestedQoutation: action((state, payload = null) => {
    state.requestedQoutation = payload
  }),
  requestQoutation: thunk(async (actions, data) => {
    actions.setRequestedQoutation({ status: 1 })
    axios.post(`${API_URL}/jobs/hire`, data)
      .then(response => actions.setRequestedQoutation({ status: 2, payload: response.data }))
      .catch(e => actions.setRequestedQoutation({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
